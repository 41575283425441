<template>
  <v-container
    fluid
    class="px-0"
  >
    <v-card class="mt-0 mb-0 bgTrans">
      <v-container
        id="regular-tables"
        fluid
        tag="section"
        class="px-0 py-0"
      >
        <v-row
          v-if="loading"
          class=""
        >
          <v-col
            cols="6"
            class="pl-0"
          >
            <v-skeleton-loader
              class="mx-auto"
              type="article, article"
            />
          </v-col>
          <v-col
            cols="6"
            class="pl-0"
          >
            <v-skeleton-loader
              class="mx-auto"
              type="image"
            />
          </v-col>
        </v-row>

        <v-row
          v-else
          class="py-0 insideOveviewTabDetails px-0 mx-0 my-0"
        >
          <v-col
            cols="6"
            class="pl-0"
          >
            <div class="leftOverviewCard">
              <h4 class="cardHeading">
                Details
              </h4>
              <ul class="overviewDetailsList">
                <li>
                  <span class="cardLabel">
                    {{ product === "oem" ? "Customer " : "Driver " }} Name
                  </span>
                  <span class="cardTextData">
                    {{ items?.active_customer_name }}
                  </span>
                </li>
                <li>
                  <span class="cardLabel">Contact Number</span>
                  <span class="cardTextData">
                    {{ items?.sim_no }}
                  </span>
                </li>
                <li>
                  <span class="cardLabel">Assigned at</span>
                  <span class="cardTextData">
                    {{ items?.customer_assigned_at }}
                  </span>
                </li>
                <li>
                  <span class="cardLabel">IoT IMEI</span>
                  <span class="cardTextData">{{ items?.iot_device_imei }}</span>
                </li>
                <li>
                  <span class="cardLabel">IoT SIM</span>
                  <span class="cardTextData"> {{ items?.sim_no }}</span>
                </li>
                <li>
                  <span class="cardLabel">Register Number</span>
                  <span class="cardTextData">{{ items?.registration_no }}</span>
                </li>
                <li>
                  <span class="cardLabel">Motor No.</span>
                  <span class="cardTextData">{{ items?.motor_no }}</span>
                </li>
                <li>
                  <span class="cardLabel">Chassis No.</span>
                  <span class="cardTextData">{{ items?.chassis_no }}</span>
                </li>
                <li>
                  <span class="cardLabel">Ignition</span>
                  <span class="cardTextData">{{ items?.ignition_status }}</span>
                </li>
                <li>
                  <span class="cardLabel">Active Since</span>
                  <span class="cardTextData">{{ items?.synced_at }}</span>
                </li>
                <li>
                  <span class="cardLabel">Daily Avg Mileage</span>
                  <span class="cardTextData">
                    {{ items?.daily_avg_mileage }} KM/L
                  </span>
                </li>
                <li>
                  <span class="cardLabel">Total Avg Mileage</span>
                  <span class="cardTextData">
                    {{ items?.total_avg_mileage }} KM/L
                  </span>
                </li>
                <li>
                  <span class="cardLabel">Daily Distance Travelled</span>
                  <span class="cardTextData">
                    {{ items?.daily_distance_travelled }} KM
                  </span>
                </li>
                <li>
                  <span class="cardLabel">Total Distance Travelled</span>
                  <span class="cardTextData">
                    {{ items?.total_distance_travelled }} KM
                  </span>
                </li>
              </ul>
            </div>
          </v-col>
          <v-col
            cols="6"
            class="pr-0"
          >
            <div class="rightOverviewCard">
              <h4 class="cardHeading">
                Map
              </h4>
              <l-map
                v-if="point"
                class="VueLeafMap"
                :zoom="zoom"
                :center="point"
                :options="mapOptions"
                style="height: 300px"
              >
                <l-tile-layer
                  :url="url"
                  :attribution="attribution"
                />
                <l-control-fullscreen
                  position="topright"
                  :options="{
                    title: { false: 'Full Screen', true: 'Dashboard View' },
                  }"
                />
                <l-marker :lat-lng="point">
                  <l-icon>
                    <VehicleMapMarkers
                      :name="items.vehicle_model_type"
                      :status="items.status"
                    />
                  </l-icon>
                </l-marker>
              </l-map>
              <div
                v-else
                class="map-error"
              >
                <v-icon x-large>
                  mdi-map-marker-off
                </v-icon>
                <span class="text-muted">No Location Found!</span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { latLng } from "leaflet";
import { debounce } from "lodash";
import { LMap, LTileLayer, LMarker, LIcon } from "vue2-leaflet";
import LControlFullscreen from "vue2-leaflet-fullscreen";
import { mapGetters } from "vuex";
export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LControlFullscreen,
    VehicleMapMarkers: () => import("@/components/base/VehicleMapMarkers.vue"),
  },
  props: {
    name: {
      type: String,
      default() {
        return "";
      },
    },
    selectedTab: {
      type: String,
      default() {
        return "";
      },
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: true,
      items: null,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 13,
      mapOptions: {
        zoomSnap: 0.5,
      },
      //
      lastVehicleID: null,
    };
  },
  computed: {
    ...mapGetters({ authUser: "getUser" }),
    product() {
      if (this.authUser.isAdmin) {
        return this.$route.query.product;
      } else {
        return this.authUser.user_nature;
      }
    },
    point() {
      const { latitude, longitude } = this.items;
      return latLng(latitude, longitude);
    },
  },
  watch: {
    "options.vehicle_id": {
      handler: async function () {
        if (this.name === this.selectedTab) {
          this.fetchData();
        }
      },
    },
    selectedTab: {
      handler: async function (v) {
        if (v === this.name) {
          const vd = this.options.vehicle_id;
          if (vd != this.lastVehicleID) this.fetchData();
        }
      },
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData: debounce(function () {
      const params = {
        ...this.options,
      };
      this.lastVehicleID = params.vehicle_id; //for Stop unwanted API call

      this.loading = true;
      this.$store
        .dispatch("telematicDetails/overview", params)
        .then((data) => {
          this.items = data;
        })
        .finally(() => (this.loading = false));
    }, 300),
  },
};
</script>
<style lang="sass" scoped>
.map-error
  display: flex
  flex-direction: column
  flex: 1
  justify-content: center
  align-items: center
</style>
