import { render, staticRenderFns } from "./OverviewTab.vue?vue&type=template&id=45e6e416&scoped=true"
import script from "./OverviewTab.vue?vue&type=script&lang=js"
export * from "./OverviewTab.vue?vue&type=script&lang=js"
import style0 from "./OverviewTab.vue?vue&type=style&index=0&id=45e6e416&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45e6e416",
  null
  
)

export default component.exports